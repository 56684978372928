<template>
  <v-app-bar app fixed elevate-on-scroll color="white">
    <v-toolbar-title class="mr-4">
      <div
        v-if="$route.name == 'Home'"
        class="primary--text"
        style="
          height: 50px;
          width: 110px;
          display: flex;
          align-items: center;
          justify-content: center;
          border: 2px solid;
          border-radius: 0 15px 0;
        "
      >
        <v-img src="@/assets/img/logo.png" max-width="35px" class="mr-2" />
        <!-- <v-icon color="primary" class="mr-2">mdi-blur</v-icon> -->
        Asset
      </div>

      <v-btn
        v-else
        icon
        to="/"
        title="Voltar">
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
    </v-toolbar-title>
    <!--  -->
    <v-scroll-y-transition hide-on-leave>
      <!-- Campo de pesquisa -->
      <!-- :shaped="menuFilter" -->
      <v-text-field
        dense
        filled
        rounded
        clearable
        hide-details
        class="textFieldSearch"
        placeholder="Pesquisar"
        style="width: 100%; max-width: 500px"
        :disabled="loading"
        :class="menuFilter ? 'rounded-b-0' : ''"
        @click:clear="
          textFieldSearch = '';
          search();
        "
        @keydown.enter="search"
        v-if="title == ''"
        v-model="textFieldSearch"
      >
        <template v-slot:prepend-inner>
          <v-fade-transition leave-absolute>
            <v-progress-circular
              v-if="loading"
              size="22"
              width="2"
              color="primary"
              style="margin-right: 2px"
              indeterminate
            ></v-progress-circular>
            <v-icon v-else>mdi-magnify</v-icon>
          </v-fade-transition>
        </template>
        <template v-slot:append>
          <v-menu
            left
            offset-y
            v-model="menuFilter"
            :close-on-content-click="false"
            class="mt-5 elevation-0"
            rounded="b-xl t-0"
            nudge-top="-6px"
            nudge-right="24px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                small
                v-bind="attrs"
                v-on="on"
                class="btn-filter"
                @click="setWidthMenu"
              >
                <v-icon :color="haveFilters ? 'primary' : ''">
                  mdi-filter-{{ haveFilters ? "check-" : "" }}outline
                </v-icon>
              </v-btn>
            </template>
            <v-card :width="widthMenu" rounded="t-0" class="px-3">
              <v-card-text class="pb-0">
                <v-row class="pt-1">
                  <v-col cols="12">
                    <v-select
                      hide-details
                      item-text="text"
                      class="ma-0 pa-0"
                      item-value="value"
                      label="Tipo do arquivo"
                      :items="assetTypes"
                      v-model="filterAssetType"
                    ></v-select>
                  </v-col>
                  <v-col cols="12">
                    <v-select
                      hide-details
                      item-text="text"
                      class="ma-0 pa-0"
                      item-value="value"
                      label="Status do arquivo"
                      :items="assetStatus"
                      v-model="filterAssetStatus"
                    ></v-select>
                  </v-col>
                  <v-col cols="12">
                    <v-select
                      hide-details
                      item-text="text"
                      class="ma-0 pa-0"
                      item-value="value"
                      label="Ordenar por"
                      :items="assetOrders"
                      v-model="filterAssetOrder"
                    ></v-select>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions>
                <v-btn text @click="cleanFilters">Limpar filtros</v-btn>
                <v-spacer></v-spacer>
                <v-btn text @click="search" color="primary">Pesquisar</v-btn>
              </v-card-actions>
            </v-card>
          </v-menu>
        </template>
      </v-text-field>
      <!-- Título da pagina do arquivo -->
      <v-toolbar-title v-else class="grey--text text--darken-2">
        {{ title }}
      </v-toolbar-title>
    </v-scroll-y-transition>
    <v-spacer></v-spacer>
    <!--  -->
    <v-fade-transition hide-on-leave>
      <div v-if="$route.meta.showBtns && title" class="mr-3">
        <v-btn text color="primary" class="mr-3" @click="openPreview()"
          >Pré-visualizar</v-btn
        >
        <v-btn
          depressed
          color="primary"
          @click="setTab()"
          v-if="title != 'Novo arquivo' && checkCredentials(['admin', 'editor'])"
          >{{ !tab ? "Editar código" : "Visualizar" }}</v-btn
        >
      </div>
    </v-fade-transition>
    <!--  -->
    <v-menu
      bottom
      rounded
      offset-y
      min-width="200px"
      transition="slide-y-transition"
      v-if="me"
    >
      <template v-slot:activator="{ on }">
        <v-btn icon x-large v-on="on">
          <v-avatar v-if="me.profile_photo_url">
            <img :src="me.profile_photo_url" :alt="me.nome" />
          </v-avatar>
          <v-avatar color="primary" v-else>
            <v-icon color="white" style="font-size: 24px">mdi-account-circle</v-icon>
          </v-avatar>
        </v-btn>
      </template>
      <v-card>
        <v-list-item-content class="justify-center pb-0">
          <div class="mx-auto text-center">
            <v-avatar v-if="me.profile_photo_url">
              <img :src="me.profile_photo_url" :alt="me.nome" />
            </v-avatar>
            <v-avatar color="primary" v-else>
              <v-icon dark>mdi-account-circle</v-icon>
            </v-avatar>
            <h3>{{ me.name }}</h3>
            <p class="text-caption mt-1">
              {{ me.email }}
            </p>

            <div class="text-center text--disabled pb-4">Versão {{ version }}</div>

            <v-list class="pa-0">
              <v-divider></v-divider>
              <v-list-item :href="authAppUrl" target="_blank">
                <v-list-item-title>Meus apps</v-list-item-title>
              </v-list-item>
              <v-divider></v-divider>
              <v-list-item @click="logoutAppBar">
                <v-list-item-title>Sair</v-list-item-title>
              </v-list-item>
            </v-list>
          </div>
        </v-list-item-content>
      </v-card>
    </v-menu>
    <v-dialog
      v-model="dialog"
      @click:outside="dialog = false"
      @keydown.esc="dialog = false"
      class="text-center"
      max-width="500px"
    >
      <v-sheet
        class="px-7 pt-7 pb-4 mx-auto text-center d-inline-block"
        color="blue-grey darken-3"
        dark
      >
        <div class="grey--text text--lighten-1 text-body-3 mb-4">
          Tem certeza que deseja descartar as alterações?
        </div>

        <v-btn class="ma-1" color="grey" plain @click="dialog = false">Cancelar</v-btn>

        <v-btn
          class="ma-1"
          color="error"
          plain
          @click="
            dialog = false;
            isEdit = false;
            toHome();
          "
        >
          Descartar
        </v-btn>
      </v-sheet>
    </v-dialog>
    <v-snackbar v-model="snackbar" :color="snackBarColor">
      <strong>{{ text }}</strong>
    </v-snackbar>
  </v-app-bar>
</template>

<script>
import { bus } from "../../main";
import { mapActions, mapGetters } from "vuex";
import enviroment from "@/assets/js/enviroment";
import { version } from "@/../package.json";

export default {
  data: () => ({
    authAppUrl: enviroment.authAppUrl,
    dialog: false,
    isEdit: false,
    loading: false,
    snackbar: false,
    snackBarColor: 'primary',
    version: version,
    menuFilter: false,
    filterAssetStatus: false,
    tab: 0,
    text: "",
    title: "",
    widthMenu: "500px",
    filterAssetType: "",
    textFieldSearch: "",
    filterAssetOrder: "-created_at",
    assetStatus: [
      { text: "Ativo", value: false },
      { text: "Excluidos", value: true },
    ],
    assetOrders: [
      { text: "Criação", value: "-created_at" },
      { text: "Atualização", value: "-updated_at" },
    ],
    assetTypes: [
      { text: "Todos", value: "" },
      { text: "PDF", value: "PDF" },
      { text: "Jinja", value: "JINJA" },
      { text: "Email", value: "EMAIL" },
      { text: "Imagem", value: "IMAGE" },
    ],
  }),
  created() {
    bus.$on("isEdit", (isEdit) => {
      if (this.isEdit && !isEdit) bus.$emit("setTab", (this.tab = 0));
      this.isEdit = isEdit;
    });
    bus.$on("snackbar", (text, color) => {
      this.text = text;
      this.snackbar = true;
      this.snackBarColor = color || 'primary'
    });
    bus.$on("title", (newTitle) => {
      this.title = newTitle;
      this.tab = newTitle == "" ? 1 : 0;
      bus.$emit("setTab", this.tab);
    });
    bus.$on("finshSearch", () => (this.loading = false));

    // Verifica se existe o parâmetro de pesquisa na URL
    if (this.$route.params.page == "undefined") {
      this.textFieldSearch = new URLSearchParams(window.location.search).get("search");
    }
  },
  computed: {
    ...mapGetters("auth", { me: "getMe" }),
    haveFilters() {
      return !this.filterAssetStatus &&
        this.filterAssetType == "" &&
        this.filterAssetOrder == "-created_at"
        ? false
        : true;
    },
  },
  watch: {
    $route() {
      if (this.$route.query.searchTerm) {
        this.textFieldSearch = this.$route.query.searchTerm;
      }
    },
  },
  methods: {
    ...mapActions("auth", ["logout", "checkCredentials"]),
    cleanFilters() {
      this.filterAssetType = "";
      this.filterAssetStatus = false;
      this.filterAssetOrder = "-created_at";
    },
    toHome() {
      if (this.$route.name == "Home") return;
      if (this.isEdit) {
        this.dialog = true;
        return;
      }
      this.$router.push({
        name: "Home",
      });
    },
    setWidthMenu() {
      let input = document.querySelector(".textFieldSearch");
      this.widthMenu = input.offsetWidth + "px";
    },
    search() {
      this.loading = true;
      this.menuFilter = false;
      if (this.textFieldSearch == null) {
        this.textFieldSearch = "";
      }
      bus.$emit("search", {
        path: this.textFieldSearch,
        content: this.textFieldSearch,
        type: this.filterAssetType,
        active: this.filterAssetStatus,
        order: this.filterAssetOrder,
      });
    },
    openPreview() {
      bus.$emit("preview");
    },
    setTab() {
      this.tab = !this.tab ? 1 : 0;
      bus.$emit("setTab", this.tab);
    },
    logoutAppBar() {
      this.logout();
      window.location = enviroment.apiUrl + "oauth/login?force_login=true";
    },
  },
};
</script>

<style lang="scss" scoped>
.btn-filter {
  margin-top: -2px;
}
</style>
