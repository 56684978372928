import store from "@/store";

const auth = (to, from, next) => {
  store.dispatch('auth/auth', to.query.token).then(() => next('/'))
}

const checkAuth = (to, from, next) => {
  document.title = to.meta.title + " - Asset"

  if(to.meta.requiresAuth !== undefined && !to.meta.requiresAuth) next();
  else store.dispatch('auth/checkAuth').then((isAuthenticated) => {
    if (isAuthenticated) next();
    else next({name: 'authenticate'});
  }).catch((err) => {
    console.error(err);
    next('/error');
  })
}

export {
  auth,
  checkAuth,
}