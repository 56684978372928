import http from "@/plugins/axios";

export default {
  /**
   * 
   * @returns 
   */
  me() {
    return http.api.get("/oauth/user/me");
  },
  /**
   * 
   * @param {*} payload 
   * @returns 
   */
  search(payload) {
      return http.api.get('/oauth/user', { payload })
  },
}