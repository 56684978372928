import auth from "./auth.store";
import { store as app } from "@/views/app";
import { store as home } from "@/views/home";
import { store as asset } from "@/views/asset";

export default {
  app,
  auth,
  home,
  asset,
};