import * as types from "./mutation-types";

/**
 * As mutations que fazem as modificações nas variaves do state.
 */
export default {
  [types.SET_PAGE](state, payload) {
    state.page = payload;
  },
  [types.SET_ASSETS](state, payload) {
    state.assets = payload;
  },
  [types.SET_FILTERS](state, payload) {
    state.filters = payload;
  },
}