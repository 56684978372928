import { routes as app } from '../views/app';
import { routes as error } from '../views/error';
import { routes as home } from '../views/home';
import { routes as asset } from '../views/asset';


const routes =  [
  ...app,
  ...error,
  ...home,
  ...asset,
];
export default routes;