// require lib
import Vue from 'vue'
import VueCodemirror from 'vue-codemirror'
 
// base style
import 'codemirror/lib/codemirror.css'

// theme css

// language
import 'codemirror/mode/xml/xml.js'

// merge css
import 'codemirror/addon/merge/merge.css'

// merge js
import 'codemirror/addon/merge/merge.js'

// active-line.js
import 'codemirror/addon/selection/active-line.js'

// autoCloseTags
import'codemirror/addon/edit/closetag.js'

// styleSelectedText
import 'codemirror/addon/search/searchcursor.js'
import 'codemirror/addon/selection/mark-selection.js'

// hint
import'codemirror/addon/hint/html-hint.js'
import'codemirror/addon/hint/show-hint.js'
import'codemirror/addon/hint/show-hint.css'

// highlightSelectionMatches
import 'codemirror/addon/scroll/annotatescrollbar.js'
import 'codemirror/addon/search/matchesonscrollbar.js'
import 'codemirror/addon/search/searchcursor.js'
import 'codemirror/addon/search/match-highlighter.js'

// keyMap
import 'codemirror/mode/clike/clike.js'
import 'codemirror/addon/edit/matchbrackets.js'
import 'codemirror/addon/comment/comment.js'
import 'codemirror/addon/dialog/dialog.js'
import 'codemirror/addon/dialog/dialog.css'
import 'codemirror/addon/search/searchcursor.js'
import 'codemirror/addon/search/search.js'
import 'codemirror/keymap/sublime.js'

// foldGutter
import 'codemirror/addon/fold/foldgutter.css'
import 'codemirror/addon/fold/brace-fold.js'
import 'codemirror/addon/fold/comment-fold.js'
import 'codemirror/addon/fold/foldcode.js'
import 'codemirror/addon/fold/foldgutter.js'
import 'codemirror/addon/fold/indent-fold.js'
import 'codemirror/addon/fold/markdown-fold.js'
import 'codemirror/addon/fold/xml-fold.js'

// google DiffMatchPatch
import DiffMatchPatch from 'diff-match-patch'

// DiffMatchPatch config with global
window.diff_match_patch = DiffMatchPatch
window.DIFF_DELETE = -1
window.DIFF_INSERT = 1
window.DIFF_EQUAL = 0
 
// you can set default global options and events when use
Vue.use(VueCodemirror, { events: ["refresh"] })