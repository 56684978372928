import Vue from 'vue';
import axios from "axios";
import enviroment from "@/assets/js/enviroment";

Vue.prototype.$http = axios;

// API principal da QuiteJá
const apiCore = axios.create({ baseURL: enviroment.apiUrl });

const setToken = token => {
  apiCore.defaults.headers['authentication-token'] = token;
};

export default {
  setToken,
  api: apiCore,
  generic: axios,
};