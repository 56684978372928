import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import vuetify from './plugins/vuetify'
import codemirror from './plugins/codemirror'
import { name, version } from '@/../package.json';

import * as Sentry from "@sentry/browser";
import { Vue as VueIntegration } from "@sentry/integrations";
import "./registerServiceWorker";

Vue.config.productionTip = false

export const bus = new Vue();

if (process.env.NODE_ENV === 'production') {
  console.debug("Configurando o Sentry");

  // Integração com o Sentry
  Sentry.init({
    dsn: 'https://d5eaf9417abc495ebb2c7ca338758db7@o92838.ingest.sentry.io/6146802',
    enviroment: (window.location.host.indexOf('.quiteja.com.br') !== -1) ? 'production' : 'staging',
    integrations: [
      new VueIntegration({
        Vue,
        attachProps: true,
        logErrors: true,
      }),
    ],
    release: name + '@' + version,
    sendDefaultPii: true,
  });
}

new Vue({
  router,
  store,
  vuetify,
  codemirror,
  render: h => h(App)
}).$mount('#app');

const moment = require("moment");
require("moment/locale/pt-br");
 
Vue.use(require("vue-moment"), {
    moment,
});

console.debug("Aplicação criada");
