export default [
  {
    path: '/404',
    alias: '*',
    name: 'PageNotFound',
    component: () => import(/* webpackChunkName: "home" */ './pages/PageNotFound'),
    meta: {
      title: 'Página não encontrada',
      showBtns: false,
    },
  },
]
