module.exports = {
  transpileDependencies: ["vuetify"],
  devServer: {
    port: 8540,
  },
  pwa: {
    manifestOptions: {
      display: "standalone",
      description: "Gerenciamento de e-mails, documentos e imagens",
      icons: [
        {
          src: "./img/icons/android-chrome-192x192.png",
          sizes: "192x192",
          type: "image/png",
        },
        {
          src: "./img/icons/android-chrome-512x512.png",
          sizes: "512x512",
          type: "image/png",
        },
      ],
      name: "Assets",
      short_name: "Assets",
      orientation: "portrait-primary",
      start_url: ".?utm_source=homescreen",
    },
    msTileColor: "#1976d2",
    themeColor: "#1976d2",
    workboxOptions: {
      exclude: [
        // Evita que o index.html seja armazenado no cache
        /index.html/,
      ],
    },
  },
};
