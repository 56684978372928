<template>
  <v-app>
    <AppBar v-if="user && user.id" />

    <v-main>
      <v-fade-transition hide-on-leave leave-absolute>
        <router-view v-if="user && user.id" />

        <v-container v-else fill-height fluid>
          <v-row align="center" justify="center">
              <v-col cols="12" class="text-center">
                <img
                  src="@/assets/img/logo.png"
                  class="logo"
                  title="Logo" />

                <v-progress-circular
                  indeterminate
                  color="primary"
                  class="d-flex mx-auto"
                ></v-progress-circular>
              </v-col>
          </v-row>
        </v-container>
      </v-fade-transition>
    </v-main>
  </v-app>
</template>

<script>
import { mapGetters } from 'vuex';
import AppBar from "./components/common/AppBar";

export default {
  name: "App",
  components: {
    AppBar,
  },
  data: () => ({}),

  computed: {
    ...mapGetters('auth', {
      user: 'getMe',
    }),
  },
};
</script>

<style lang="scss">
.logo {
  height: 80px;
  margin-bottom: 16px;
  width: 80px;
}
</style>
