export default [
  {
    path: '/asset',
    beforeEnter: (to, from, next) => {
      next({
        name: 'Home',
        query: to.query
      });
    },
  },
  {
    path: '/asset/:id/:page?',
    name: 'Asset',
    component: () => import(/* webpackChunkName: "asset" */ './pages/Asset'),
    meta: {
      title: 'Arquivo',
      showBtns: true,
    },
  },
]
