/* eslint-disable */
import http from "@/plugins/axios";
import oauth from '../services/oauth'
import enviroment from "@/assets/js/enviroment";

import * as Sentry from "@sentry/browser";

const state = {
  me: null,
  token: null,
}

const mutations = {
  setToken(state, token) {
    state.token = token;
    localStorage.setItem('ASSET_TOKEN', token);
    http.setToken(token);
  },
  setMe(state, me) {
    state.me = me;
    localStorage.setItem('ASSET_USER', JSON.stringify(me));

    Sentry.setUser({
      id: me.id,
      email: me.email,
      username: me.name,
    });
  },
}

const actions = {
  auth({ commit, dispatch }, token) {
    return new Promise(async (resolve, reject) => {
      if (token) {
        commit('setToken', token);
      } else {
        console.error('Autenticação sem token');
        window.location = enviroment.apiUrl + 'oauth/login';
        return;
      }
      await oauth.me().then(res => {
        commit('setMe', res.data);
        resolve();
      }).catch((err) => {
        console.error(err);
        dispatch('logout');
        window.location = enviroment.apiUrl + 'oauth/login';
      });
    });
  },
  logout() {
    state.me = null
    state.token = null
    localStorage.removeItem('ASSET_TOKEN')
    localStorage.removeItem('ASSET_USER')
  },
  checkAuth({ commit }) {
    if (state.me && state.token) return true;

    const token = localStorage.getItem('ASSET_TOKEN');
    const me = JSON.parse(localStorage.getItem('ASSET_USER'));
    if (token && me) {
      commit('setMe', me);
      commit('setToken', token);
      http.setToken(token);
      return true;
    }
    return false;
  },
  checkCredentials({}, credentials = []) {
    let gotCredentials = false;
    state.me?.roles?.forEach((credential) => {
      if (credentials.includes(credential)) {
        gotCredentials = true;
      }
    });
    return gotCredentials;
  },
}

const getters = {
  getMe({ me }) { return me }
}

export default {
  state,
  mutations,
  actions,
  getters,
  namespaced: true,
};