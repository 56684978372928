import store from "@/store";
import assets from "@/services/assets";
import * as types from "./mutation-types";

/* INI SETTERS */
/**
 * Função que armazena a página.
 * 
 * @param {Function} commit Função para armazenar no state.
 * @param {Number} payload Número da página.
 */
 export const ActionsSetPage = ({ commit }, payload) => {
  commit(types.SET_PAGE, payload);
};
/**
 * Função que armazena as menssagem de todos os credores.
 * 
 * @param {Function} commit Função para armazenar no state.
 * @param {Array<Object>} payload Messagens dos credores.
 */
 export const ActionsSetAssets = ({ commit }, payload) => {
  commit(types.SET_ASSETS, payload);
};
/**
 * Função que armazena os filtros.
 * 
 * @param {Function} commit Função para armazenar no state.
 * @param {Array<Object>} payload Filtros.
 */
 export const ActionsSetFilters = ({ commit }, payload) => {
  commit(types.SET_FILTERS, payload);
};
/* FIM SETTERS */

/* INI ACTIONS */
/**
 * Função que armazena as menssagem de todos os credores.
 * 
 * @param {Function} dispatch Função para executar uma Action.
 * @param {Object} payload Messagens dos credores.
 */
 export const ActionsAssets = ({ dispatch }, payload) => {
  return assets
    .assets(payload)
    .then((resp) => {
      if (!resp.data.objects.length) return [];
      let newAssets = resp.data.objects;
      let storeAssets = store.getters["home/getAssets"];
      let concatAssets = storeAssets.concat(newAssets);
      dispatch("ActionsSetAssets", concatAssets);
      return concatAssets;
    });
};
/**
 * Função que redefine os valores.
 * 
 * @param {Function} dispatch Função para executar uma Action.
 */
export const ActionsReset = ({ dispatch }) => {
  dispatch("ActionsSetPage", 1);
  dispatch("ActionsSetAssets", []);
  dispatch("ActionsSetFilters", {});
};
/* FIM ACTIONS */