export default [
  {
    path: '/',
    name: 'Home',
    component: () => import(/* webpackChunkName: "home" */ './pages/Home'),
    meta: {
      title: 'Início',
      showBtns: false,
    },
  },
]
