  import Vue from 'vue';
  import routes from './routes';
  import VueRouter from 'vue-router';
  import { auth, checkAuth } from './beforeEach'
  import { publicPath } from '../../vue.config'
  
  Vue.use(VueRouter);
  
  const router = new VueRouter({ 
    routes: [
      ...routes,
      {
        path: '/authenticate',
        name: 'authenticate',
        beforeEnter: auth,
        meta: {
          requiresAuth: false
        }
      },
    ],
    mode: 'history',
    base: publicPath,
  });
  
  router.onError((error) => {
    console.error("Não foi possível carregar a rota.", error);
  });

  router.beforeEach(checkAuth)
  
  window.$router = router;
  
  export default router;