export default [
  {
    path: '/snapshot/:id',
    name: 'Snapshot',
    component: () => import(/* webpackChunkName: "snapshot" */ './pages/Snapshot'),
    meta: {
      title: 'Snapshot',
      showBtns: false,
    },
  },
]
