import http from "@/plugins/axios";

export default {
  /**
   * 
   * @param {Object} payload 
   * @returns 
   */
   asset(id) {
    let url = "v2/assets/" + id;
    return http.api.get(url);
  },
  /**
   * 
   * @param {Object} payload 
   * @returns 
   */
   assets(payload) {
    let url = "v2/assets?"
    Object.entries(payload).forEach((value, index) => {
      if (index == 0) {
        url += value[0] + "=" + value[1];
      } else {
        url += "&" + value[0] + "=" + value[1];
      }
    });
    return http.api.get(url);
  },
  /**
   * 
   * @param {Object} payload 
   * @returns 
   */
  new(payload) {
    return http.api.post("v2/assets", payload);
  },
  /**
   * 
   * @param {Object} payload 
   * @returns 
   */
  edit(id, payload) {
    return http.api.patch("v2/assets/" + id, payload);
  },
  /**
   * 
   * @param {String} id 
   * @returns 
   */
   assetsHistory(id) {
    return http.api.get("v2/assets_history?asset_id=" + id);
  },
}