 /**
 * Função para pegar as menssagem de todos os credores.
 * 
 * @param {Array<Object>} assets Array de menssagens dos credotes.
 * @return Array de menssagens.
 */
export const getAssets = ({ assets }) => { return assets };
 /**
 * Função para pegar a página.
 * 
 * @param {Number} page Página atual.
 * @return numero da página.
 */
export const getPage = ({ page }) => { return page };
 /**
 * Função para pegar os filtros.
 * 
 * @param {Number} filters filtros atuais.
 * @return filtros.
 */
export const getFilters = ({ filters }) => { return filters };